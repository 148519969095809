import React from 'react';

const Amex = () => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        fill="#2557D6"
        d="M46,12.5v12.3v1v1.1h0v1.5c0,0,0,0,0,0v7.1c0,1.3-1.1,2.3-2.3,2.3H4.3c-1.3,0-2.3-1.1-2.3-2.3V23.2v-3.8
	v-6.9c0-1.3,1.1-2.3,2.3-2.3h39.3C44.9,10.2,46,11.2,46,12.5z"
      />
      <path
        fill="#FFFFFF"
        d="M5.2,17.9l-0.8,2H6L5.2,17.9z M34.4,17.9l-0.8,2h1.7L34.4,17.9z M2.1,23.2h2.1L4.7,22h1.1l0.5,1.1h4.1v-0.9
	l0.4,0.9h2.2l0.4-0.9v0.9h10.3l0-1.9h0.2c0.1,0,0.2,0,0.2,0.2v1.6h5.3v-0.4c0.4,0.2,1.1,0.4,2,0.4h2.2l0.5-1.1H35l0.5,1.1h4.3v-1.1
	l0.7,1.1h3.5V16h-3.4v0.8L40,16h-3.5v0.8L36,16h-4.7c-0.8,0-1.5,0.1-2.1,0.4V16H26v0.4c-0.4-0.3-0.8-0.4-1.4-0.4h-12l-0.8,1.8L11,16
	H7.2v0.8L6.8,16H3.6l-1.5,3.4V23.2L2.1,23.2L2.1,23.2z M29.5,17.6c0.4-0.4,1-0.6,1.9-0.6h1.2v1.1h-1.2c-0.5,0-0.7,0.1-0.9,0.3
	C30.2,18.6,30,19,30,19.6c0,0.5,0.1,0.9,0.3,1.2c0.2,0.2,0.5,0.3,0.9,0.3h0.6l1.7-4h1.8l2.1,4.9V17h1.9l2.2,3.6V17h1.3v5.1H41
	l-2.3-3.9v3.9h-2.5L35.7,21h-2.6l-0.5,1.1h-1.4c-0.6,0-1.4-0.1-1.8-0.6c-0.4-0.4-0.7-1-0.7-2C28.8,18.8,28.9,18.1,29.5,17.6z
	 M26.9,17h1.3v5.1h-1.3V17z M21.3,17h2.8c0.6,0,1.1,0,1.5,0.2c0.4,0.2,0.6,0.6,0.6,1.1c0,0.8-0.5,1.2-0.9,1.4
	c0.3,0.1,0.5,0.3,0.6,0.4c0.2,0.3,0.2,0.5,0.2,0.9v1h-1.2l0-0.6c0-0.3,0-0.8-0.2-1c-0.2-0.2-0.5-0.2-0.9-0.2h-1.3v1.9h-1.2V17z
	 M16.3,17h4.1v1.1h-2.9V19h2.8V20h-2.8v1h2.9v1.1h-4.1V17z M4.3,17h1.8l2.1,4.9V17h2l1.6,3.5l1.5-3.5h2.1v5.1l0,0l0,0h-1.3l0-4
	l-1.8,4h-1.1l-1.8-4v4H6.9L6.5,21H3.9l-0.5,1.1H2.1L4.3,17z M24.6,19.1c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5
	c-0.1-0.1-0.4-0.1-0.6-0.1h-1.5v1.1H24C24.3,19.2,24.5,19.2,24.6,19.1z M21,30.5v-4.1l-1.9,2L21,30.5z M42.1,25.2v-0.4h-3.4
	c-0.5,0-1.2,0.1-1.5,0.4v-0.4h-6v0.4c-0.5-0.3-1.3-0.4-1.7-0.4h-4v0.4c-0.4-0.4-1.2-0.4-1.7-0.4h-4.4l-1,1.1l-1-1.1h-6.6V32h6.5
	l1-1.1l1,1.1l4,0v-1.7h0.4c0.5,0,1.2,0,1.7-0.3V32h3.3v-1.9h0.2c0.2,0,0.2,0,0.2,0.2V32h10.1c0.6,0,1.3-0.2,1.7-0.5V32h3.2
	c0.7,0,1.3-0.1,1.8-0.3v-1.3C45.6,30.8,45,31,44.2,31h-2.4v-1.1h2.4c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.1-0.2,0.1-0.3
	c0-0.2-0.1-0.3-0.2-0.3C44.6,29,44.5,29,44.3,29c-1.2,0-2.6,0-2.6-1.6c0-0.7,0.5-1.5,1.8-1.5h2.5v-1h-2.3C43,24.8,42.4,25,42.1,25.2
	z M23.8,29.3h-1.5V31h-2.4l-1.5-1.7L16.8,31h-4.9v-5.1h5l1.5,1.7l1.6-1.7h3.9c1,0,2.1,0.3,2.1,1.7C26,29,24.9,29.3,23.8,29.3z
	 M31.2,29.1c0.2,0.3,0.2,0.5,0.2,0.9v1h-1.2v-0.6c0-0.3,0-0.8-0.2-1c-0.2-0.2-0.5-0.2-0.9-0.2h-1.3V31h-1.2v-5.1h2.8
	c0.6,0,1.1,0,1.5,0.2c0.4,0.2,0.6,0.6,0.6,1.1c0,0.8-0.5,1.2-0.9,1.4C30.9,28.7,31.1,28.9,31.2,29.1z M36.3,26.9h-2.9v0.9h2.8v1
	h-2.8v1l2.9,0V31h-4.1v-5.1h4.1L36.3,26.9L36.3,26.9z M39.5,31h-2.4v-1.1h2.4c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.1-0.2,0.1-0.3
	c0-0.2-0.1-0.3-0.2-0.3C39.9,29,39.7,29,39.5,29c-1.2,0-2.6,0-2.6-1.6c0-0.7,0.5-1.5,1.8-1.5h2.5V27h-2.3c-0.2,0-0.4,0-0.5,0.1
	c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.3,0.1,0.5,0.1l0.7,0c0.7,0,1.1,0.1,1.4,0.4c0.2,0.2,0.4,0.6,0.4,1.1
	C41.4,30.5,40.7,31,39.5,31z M29.9,27c-0.2-0.1-0.4-0.1-0.6-0.1h-1.5v1.2h1.5c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.3,0.3-0.5
	C30.2,27.3,30.1,27.1,29.9,27z M13.1,26.9v0.9h2.7v1.1h-2.7v1h3l1.4-1.5l-1.4-1.5H13.1z M23.9,26.9h-1.6v1.3h1.6
	c0.5,0,0.8-0.2,0.8-0.7C24.6,27.1,24.3,26.9,23.9,26.9z M43.2,27C43,27.1,43,27.2,43,27.4c0,0.2,0.1,0.3,0.3,0.4
	c0.1,0,0.3,0.1,0.5,0.1l0.7,0c0.7,0,1.1,0.1,1.4,0.4c0.1,0,0.1,0.1,0.1,0.1v-1.5h-2.2C43.4,26.9,43.3,26.9,43.2,27z"
      />
    </svg>
  );
};

export default Amex;
