import React from 'react';

const Visa = () => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        fill="#0E4595"
        d="M43.7,10.2H4.3c-1.3,0-2.3,1.1-2.3,2.3v22.9c0,1.3,1.1,2.3,2.3,2.3h39.3c1.3,0,2.3-1.1,2.3-2.3V12.5
	C46,11.2,44.9,10.2,43.7,10.2z"
      />
      <path
        fill="#FFFFFF"
        d="M37,25.7L37,25.7L37,25.7z M20.3,18.3l-2,11.5h3.1l2-11.5H20.3z M29.7,22.9c-1.1-0.5-1.8-0.9-1.8-1.4
	c0-0.5,0.6-1,1.8-1c1,0,1.8,0.2,2.3,0.4l0.3,0.1l0.4-2.5c-0.6-0.2-1.6-0.5-2.8-0.5c-3.1,0-5.3,1.6-5.3,3.8c0,1.6,1.6,2.6,2.7,3.1
	c1.2,0.6,1.6,0.9,1.6,1.4c0,0.8-1,1.1-1.9,1.1c-1.3,0-1.9-0.2-2.9-0.6l-0.4-0.2l-0.4,2.6c0.7,0.3,2.1,0.6,3.5,0.6
	c3.3,0,5.4-1.5,5.5-3.9C32.3,24.7,31.5,23.7,29.7,22.9z M38.4,18.3c-0.8,0-1.3,0.2-1.6,1l-4.6,10.5h3.3c0,0,0.5-1.4,0.7-1.7
	c0.4,0,3.6,0,4,0c0.1,0.4,0.4,1.7,0.4,1.7h2.9l-2.5-11.5L38.4,18.3L38.4,18.3z M37,25.7L37,25.7L37,25.7c0.3-0.7,1.2-3.2,1.2-3.2
	c0,0,0.3-0.7,0.4-1.1l0.2,1c0,0,0.6,2.7,0.7,3.3H37L37,25.7z M15.7,18.3l-3.1,7.8l-0.3-1.6c0,0,0-0.1,0-0.1l-1.1-5.1
	c-0.2-0.7-0.7-0.9-1.4-1h-5l0,0.2C5.9,18.8,7,19.3,8,19.8l2.8,9.9l3.3,0L19,18.3L15.7,18.3L15.7,18.3z"
      />
    </svg>
  );
};

export default Visa;
