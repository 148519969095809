import React from 'react';

const Discover = () => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        fill="#4D4D4D"
        d="M46,13.2V25v9.8c0,1.7-1.4,3-3.1,3H5.1c-1.7,0-3.1-1.4-3.1-3V13.2c0-1.7,1.4-3,3.1-3h37.8
   C44.6,10.2,46,11.5,46,13.2z"
      />
      <path
        fill="#FFFFFF"
        d="M37.7,23.9h-2.1v-1.5h2v-1h-2v-1.3h2.1v-1h-3.3v5.6h3.3V23.9z M39.7,22.6h0.2l1.6,2.3h1.4L41,22.5
   c0.9-0.2,1.4-0.7,1.4-1.6c0-1.1-0.8-1.7-2.1-1.7h-1.7v5.6h1.2V22.6z M39.7,20.1H40c0.7,0,1.1,0.3,1.1,0.8c0,0.6-0.4,0.9-1.1,0.9
   h-0.3V20.1z M16.9,23.2c0-0.9-0.4-1.3-1.7-1.8c-0.7-0.2-0.9-0.4-0.9-0.7c0-0.4,0.4-0.6,0.9-0.6c0.3,0,0.6,0.1,0.9,0.5l0.6-0.8
   c-0.5-0.4-1.1-0.6-1.7-0.6c-1.1,0-1.9,0.7-1.9,1.6c0,0.8,0.4,1.2,1.5,1.5c0.5,0.2,0.7,0.3,0.8,0.3c0.2,0.1,0.3,0.3,0.3,0.6
   c0,0.5-0.4,0.8-0.9,0.8c-0.6,0-1-0.3-1.3-0.8L12.8,24c0.5,0.7,1.2,1.1,2.1,1.1C16.1,25,16.9,24.3,16.9,23.2z M31.4,25l2.6-5.8h-1.3
   L31.1,23l-1.6-3.8h-1.3l2.5,5.8H31.4z M20.4,25c0.5,0,0.9-0.1,1.5-0.3v-1.3C21.4,23.8,21,24,20.5,24c-1.2,0-2-0.8-2-1.9
   c0-1.1,0.9-1.9,1.9-1.9c0.6,0,1,0.2,1.5,0.6v-1.3c-0.5-0.2-0.9-0.3-1.4-0.3c-1.8,0-3.2,1.3-3.2,3C17.3,23.7,18.7,25,20.4,25z
    M11.2,19.3h1.2v5.6h-1.2V19.3z M7.5,19.3H5.8v5.6h1.7c0.9,0,1.5-0.2,2.1-0.7c0.7-0.5,1.1-1.3,1.1-2.2C10.7,20.4,9.4,19.3,7.5,19.3z
    M8.9,23.5c-0.4,0.3-0.8,0.4-1.6,0.4H7v-3.7h0.3c0.8,0,1.2,0.1,1.6,0.5c0.4,0.3,0.6,0.9,0.6,1.4C9.5,22.6,9.3,23.2,8.9,23.5z"
      />
      <path
        fill="#F47216"
        d="M25.4,24.9c1.7,0,3.2-1.3,3.2-2.9l0,0c0-1.6-1.4-2.9-3.2-2.9c-1.7,0-3.2,1.3-3.2,2.9l0,0
   C22.3,23.6,23.7,24.9,25.4,24.9z M42.9,37.8c1.7,0,3.1-1.4,3.1-3v-8.7c-1.5,1-12.5,8.2-31.5,11.7H42.9z"
      />
    </svg>
  );
};

export default Discover;
