import React from 'react';

const Mastercard = () => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        d="M43.7,10.2H4.3c-1.3,0-2.3,1.1-2.3,2.3v22.9c0,1.3,1.1,2.3,2.3,2.3h39.3c1.3,0,2.3-1.1,2.3-2.3V12.5
	C46,11.2,44.9,10.2,43.7,10.2z"
      />
      <path
        fill="#FFFFFF"
        d="M26.2,32.9v-0.3h-0.5v2.3h0.5v-1.3c0-0.4,0.2-0.6,0.5-0.6c0.1,0,0.2,0,0.3,0l0.1-0.4c-0.1,0-0.2,0-0.3,0
	C26.6,32.5,26.4,32.7,26.2,32.9z M24.1,32.5L24.1,32.5c-0.7,0-1.1,0.5-1.1,1.2s0.5,1.2,1.2,1.2c0.3,0,0.7-0.1,0.9-0.3l-0.2-0.3
	c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0-0.7-0.2-0.7-0.6h1.7v-0.2C25.2,33,24.8,32.5,24.1,32.5z M23.5,33.6c0.1-0.3,0.3-0.6,0.7-0.6
	c0.3,0,0.6,0.2,0.6,0.6H23.5z M27.4,33.8L27.4,33.8L27.4,33.8L27.4,33.8z M14.1,32.5c-0.3,0-0.6,0.1-0.8,0.4
	c-0.2-0.3-0.4-0.4-0.8-0.4c-0.3,0-0.5,0.1-0.7,0.3v-0.3h-0.5v2.3h0.5v-1.3c0-0.4,0.2-0.6,0.6-0.6c0.3,0,0.5,0.2,0.5,0.6v1.3h0.5
	v-1.3c0-0.4,0.2-0.6,0.6-0.6c0.3,0,0.5,0.2,0.5,0.6v1.3H15v-1.5C15,32.9,14.6,32.5,14.1,32.5z M19.6,33.5l-0.2,0
	c-0.2,0-0.4-0.1-0.4-0.2c0-0.2,0.2-0.3,0.5-0.3c0.3,0,0.6,0.1,0.7,0.2l0.2-0.4c-0.2-0.2-0.6-0.2-1-0.2c-0.6,0-1,0.3-1,0.7
	c0,0.4,0.3,0.6,0.8,0.7l0.2,0c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.6,0.3c-0.3,0-0.6-0.1-0.8-0.2l-0.2,0.4c0.3,0.2,0.8,0.3,1,0.3
	c0.7,0,1-0.3,1-0.8C20.4,33.8,20.1,33.6,19.6,33.5z M22.1,34.5c-0.2,0-0.4-0.1-0.4-0.4V33h0.9v-0.4l0,0h-0.9v-0.7h-0.5v0.7h-0.5V33
	h0.5v1.1c0,0.5,0.2,0.9,0.8,0.9c0.2,0,0.4-0.1,0.6-0.2l-0.1-0.4C22.4,34.5,22.2,34.5,22.1,34.5z M17.4,32.9
	c-0.2-0.2-0.4-0.3-0.8-0.3c-0.7,0-1.1,0.5-1.1,1.2s0.5,1.2,1.1,1.2c0.3,0,0.6-0.1,0.8-0.3v0.3h0.5v-1.2v-1.2h-0.5V32.9z M16.7,34.5
	c-0.5,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.7-0.8c0.4,0,0.7,0.3,0.7,0.8C17.4,34.2,17.1,34.5,16.7,34.5z M28.6,33
	c0.2,0,0.4,0.1,0.6,0.2l0.2-0.4c-0.2-0.2-0.5-0.3-0.8-0.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.3,0,0.6-0.1,0.8-0.3
	l-0.2-0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.3-0.7-0.8C27.9,33.3,28.2,33,28.6,33z M31.6,32.9c-0.2-0.2-0.4-0.3-0.8-0.3
	c-0.7,0-1.1,0.5-1.1,1.2s0.5,1.2,1.1,1.2c0.3,0,0.6-0.1,0.8-0.3v0.3h0.5v-1.2v-1.2h-0.5L31.6,32.9L31.6,32.9z M31,34.5
	c-0.5,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.7-0.8c0.4,0,0.7,0.3,0.7,0.8C31.7,34.2,31.4,34.5,31,34.5z M33.9,32.5L33.9,32.5L33.9,32.5
	z M36.3,31.7v1.2c-0.2-0.2-0.4-0.3-0.8-0.3c-0.7,0-1.1,0.5-1.1,1.2c0,0.7,0.5,1.2,1.1,1.2c0.3,0,0.6-0.1,0.8-0.3v0.3h0.5v-1.2v-2.1
	L36.3,31.7L36.3,31.7z M35.7,34.5c-0.5,0-0.7-0.3-0.7-0.8l0,0c0-0.4,0.3-0.8,0.7-0.8c0.4,0,0.7,0.3,0.7,0.8
	C36.4,34.2,36.1,34.5,35.7,34.5z M33.3,32.9v-0.3h-0.5v2.3h0.5v-1.3c0-0.4,0.2-0.6,0.5-0.6c0.1,0,0.2,0,0.3,0l0.1-0.4
	c-0.1,0-0.2,0-0.3,0C33.6,32.5,33.4,32.7,33.3,32.9z"
      />
      <path fill="#FF5F00" d="M24,14.9L24,14.9c-2.1,1.7-3.4,4.2-3.4,6.9s1.2,5.2,3.3,6.9h0c2.1-1.7,3.3-4.2,3.3-6.9S26.1,16.6,24,14.9z" />
      <path
        fill="#EB001B"
        d="M20.7,21.8c0-2.7,1.2-5.2,3.3-6.9c-3.6-2.8-8.7-2.4-11.8,0.9s-3.1,8.5,0,11.9s8.2,3.8,11.8,0.9
	C21.9,27,20.7,24.5,20.7,21.8z"
      />
      <path
        fill="#F79E1B"
        d="M33.2,13.9c-3-1.5-6.6-1.1-9.2,1c2.1,1.7,3.3,4.2,3.3,6.9S26.1,27,24,28.6c2.6,2.1,6.2,2.5,9.2,1
	s4.9-4.5,4.9-7.9C38.2,18.4,36.2,15.4,33.2,13.9z"
      />
    </svg>
  );
};

export default Mastercard;
